/**
 * Converts string of words to kebab case.
 * @param {string} text - A string of words.
 * @returns {string} the string of words kababified.
 */
function toKebabCase(text) {
  if (!text) {
    return '';
  }
  return text.toLowerCase().replace(/[^a-zA-Z0-9+&]/g, '-');
}

export default toKebabCase;
