import React from 'react';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import FormattedMultilineRow from '../../components/common/FormattedMultilineRow.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import FormattedDate from '../../components/FormattedDate.jsx';
import MaintenanceActivitiesForm from '../../components/MaintenanceActivityForm.jsx';
import MaintenanceRequirementLabel from '../../components/MaintenanceRequirementLabel.jsx';
import toKebabCase from '../../shared/kebabify.js';

const sortOptions = {
  defaultSort: {
    field: 'ActivityDate',
    // show the newest entry first
    reverse: true,
  },
  fieldGetters: {
    MaintainerDisplayName: (activity) => activity.Maintainer?.FullName || '',
  },
};

function MaintenanceActivitiesTable({ maintenanceActivities, equipment, setTableReload }) {
  const [sortedActivities, handleSortSelection, sort] = useSort(maintenanceActivities, sortOptions);

  return (
    <Box sx={{ mb: 2, mt: 3 }}>
      <Grid
        container
        columns={15}
        spacing={2}
        sx={{ alignItems: 'flex-end', justifyContent: 'center', px: 1 }}
      >
        <Grid size={15}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4" align="center">
              Maintenance Activities
            </Typography>
            <Typography variant="h4" align="center">
              <MaintenanceActivitiesForm equipment={equipment} setTableReload={setTableReload} />
            </Typography>
          </Stack>
        </Grid>
        <Grid size={2}>
          <ColumnHeader
            id="ActivityDate"
            display="Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={3}>
          <ColumnHeader
            id="MaintainerDisplayName"
            display="Maintainer"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={4}>
          <ColumnHeader
            id="Notes"
            display="Notes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={2}>
          <ColumnHeader
            id="CurrentOperatingHours"
            display="Operating Hours"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={4}>
          <ColumnHeader
            id="MaintenanceRequirements"
            display="Maintenance Requirements"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedActivities} displayName="maintenance activities" />
      {sortedActivities.map((activity) => (
        <React.Fragment key={activity.Id}>
          <Grid
            container
            columns={15}
            spacing={2}
            data-cy={`maintenance-activity-${activity.CurrentOperatingHours}-${toKebabCase(
              activity?.Maintainer?.FullName
            )}`}
            sx={{ px: 1, alignItems: 'center' }}
          >
            <Grid size={2}>
              <FormattedDate
                dateString={activity.ActivityDate}
                formatOptions={DateTime.DATE_SHORT}
              />
            </Grid>
            <Grid size={3} data-cy="activity-maintainer">
              {activity?.Maintainer?.FullName}
            </Grid>
            <Grid size={4}>
              {activity.Notes ? <FormattedMultilineRow cell={activity.Notes} /> : '-'}
            </Grid>
            <Grid size={2}>{activity.CurrentOperatingHours}</Grid>
            <Grid size={4}>
              {activity.MaintenanceRequirements.map((mr) => (
                <Chip
                  key={mr.Id}
                  data-cy={`maintenance-requirement-${mr.Id}`}
                  label={<MaintenanceRequirementLabel requirement={mr} />}
                  size="small"
                  sx={{ my: 0.5 }}
                />
              ))}
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default MaintenanceActivitiesTable;
