import React from 'react';
import { DateTime } from 'luxon';

import WarningIcon from '@mui/icons-material/Warning';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import DocumentLink from '../../components/common/DocumentLink.jsx';
import FormattedMultilineRow from '../../components/common/FormattedMultilineRow.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import MaintenanceRequirementsForm from '../../components/MaintenanceRequirementsForm.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';

const DAYS_IN_YEAR = 365.244;
const MONTHS_IN_YEAR = 12;

const sortOptions = {
  defaultSort: {
    field: 'interval',
    reverse: false,
  },
  fieldGetters: {
    interval: (requirement) => {
      if (requirement.IntervalUnits === 'Days') {
        return requirement.IntervalPeriod * 1;
      }
      if (requirement.IntervalUnits === 'Weeks') {
        return requirement.IntervalPeriod * 7;
      }
      if (requirement.IntervalUnits === 'Months') {
        return (requirement.IntervalPeriod * DAYS_IN_YEAR) / MONTHS_IN_YEAR;
      }
      if (requirement.IntervalUnits === 'Weeks') {
        return requirement.IntervalPeriod * DAYS_IN_YEAR;
      }

      // Make all "Operating Hours" values really big to sort to the end
      return 1000000 + requirement.IntervalPeriod;
    },
  },
};

function MaintenanceRequirementsTable({
  // Props
  equipment,
  maintenanceRequirements,
  setTableReload,
}) {
  const roles = useAuth0Roles();
  const isAdmin = React.useMemo(() => roles.includes('Progress-Tracker Admin'), [roles]);
  const [sortedRequirements, handleSortSelection, sort] = useSort(
    maintenanceRequirements,
    sortOptions
  );

  return (
    <Box sx={{ my: 3 }}>
      <Grid container spacing={2} px={1} sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
        <Grid size={12}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4" align="center">
              Maintenance Requirements
            </Typography>
            <MaintenanceRequirementsForm equipment={equipment} setTableReload={setTableReload} />
          </Stack>
        </Grid>
        <Grid size={4}>
          <ColumnHeader
            id="ProcedureName"
            display="Procedure"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={2}>
          <ColumnHeader
            id="interval"
            display="Interval Period"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={2}>
          <ColumnHeader
            id="notes"
            display="Notes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={2}>
          <ColumnHeader
            id="lastPerformed"
            display="Last Performed"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={2}>
          <ColumnHeader
            id="operatingHours"
            display="Operating Hours"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedRequirements} displayName="maintenance requirements" />{' '}
      {sortedRequirements.map(function RequirementRow(requirement) {
        const operatingHours = requirement.OperatingHours || '-';

        return (
          <React.Fragment key={requirement.Id}>
            <Tooltip
              PopperProps={{
                'data-cy': 'maintenance-requirement-disabled-tooltip',
              }}
              title={requirement.IsDisabled && 'This requirement is disabled.'}
              followCursor
            >
              <Grid
                container
                spacing={2}
                px={1}
                sx={{
                  opacity: requirement.IsDisabled ? 0.5 : 1.0,
                  alignItems: 'center',
                }}
                data-cy={
                  requirement.IsDisabled
                    ? `disabled-requirement-${requirement.Id}`
                    : `enabled-requirement-${requirement.Id}`
                }
              >
                <Grid size={4} data-cy={`procedure-name-${requirement.ProcedureId}`}>
                  <Stack
                    spacing={1}
                    direction="row"
                    sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
                  >
                    {requirement.ThresholdExceeded && (
                      <Tooltip title="Maintenance Required" arrow placement="bottom-start">
                        <WarningIcon
                          color="warning"
                          data-cy={`threshold-exceeded-${requirement.IntervalPeriod}-${requirement.IntervalUnits}`}
                        />
                      </Tooltip>
                    )}
                    <DocumentLink
                      documentId={requirement.ProcedureId}
                      data-cy={`procedure-id-${requirement.ProcedureId}`}
                    />
                  </Stack>
                </Grid>
                <Grid size={2} data-cy={`interval-period-${requirement.IntervalPeriod}`}>
                  {`${requirement.IntervalPeriod} ${requirement.IntervalUnits}`}
                </Grid>
                <Grid size={2}>
                  {requirement.Notes ? <FormattedMultilineRow cell={requirement.Notes} /> : '-'}
                </Grid>
                <Grid size={2}>
                  {requirement.lastPerformed?.ActivityDate
                    ? DateTime.fromISO(requirement.lastPerformed.ActivityDate).toLocaleString(
                        DateTime.DATE_SHORT
                      )
                    : '-'}
                </Grid>
                <Grid size={2}>
                  <Stack spacing={1} direction="row" justifyContent="space-between">
                    <div>{operatingHours}</div>
                    <div>
                      {isAdmin && (
                        <MaintenanceRequirementsForm
                          equipment={equipment}
                          requirement={requirement}
                          setTableReload={setTableReload}
                        />
                      )}
                    </div>
                  </Stack>
                </Grid>
              </Grid>
            </Tooltip>
            <Divider variant="middle" sx={{ mx: -1 }} />
          </React.Fragment>
        );
      })}
    </Box>
  );
}

export default MaintenanceRequirementsTable;
