import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function EquipmentHeader({
  // Props
  sort,
  setSort,
}) {
  //---------------------------------------------------------------------------
  // Update the `sort` object for use by sortManager
  //---------------------------------------------------------------------------
  const handleSortSelection = (field) => setSort(field);

  return (
    <Box sx={{ p: 1 }}>
      <Grid
        container
        columns={20}
        id="headersRow"
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        <Grid size={{ xs: 10, sm: 4 }}>
          <ColumnHeader
            id="Name"
            display="Name"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 10, sm: 2 }}>
          <ColumnHeader
            id="Serial"
            display="Serial Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ sm: 4, md: 2 }}>
          <ColumnHeader
            id="Manufacturer"
            display="Manufacturer"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ sm: 3, md: 2 }}>
          <ColumnHeader
            id="Location"
            display="Location"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ md: 2, sm: 4 }}>
          <ColumnHeader
            id="LastActivity"
            display="Last Activity"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ md: 2, sm: 4 }}>
          <ColumnHeader
            id="NextActivity"
            display="Next Activity"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={2}>
          <ColumnHeader
            id="ControlledProcesses"
            display="Processes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={3}>
          <ColumnHeader
            id="CurrentOperatingHours"
            display="Current Operating Hours"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default EquipmentHeader;
