import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import ControlledProcessForm from '../../components/ControlledProcessForm.jsx';
import FormattedParameters from '../../components/FormattedParameters.jsx';

const sortOptions = {
  defaultSort: {
    field: 'Serial',
    reverse: false,
  },
};

function EquipmentTable({
  // Props
  equipment,
  part,
  partList,
  setTableReload,
}) {
  const [sortedEquipment, handleSortSelection, sort] = useSort(equipment || [], sortOptions);

  return (
    <Box>
      <Grid
        container
        spacing={2}
        columns={16}
        sx={{ px: 1, alignItems: 'flex-end', justifyContent: 'center' }}
      >
        <Grid size={16}>
          <Typography variant="h4" align="center">
            Equipment
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 2 }}>
          <ColumnHeader
            id="Name"
            display="Name"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 5, sm: 2 }}>
          <ColumnHeader
            id="Serial"
            display="Serial Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 5, sm: 2 }}>
          <ColumnHeader
            id="Location"
            display="Location"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ColumnHeader id="MaterialPartNumber" display="Material PN" />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={4}>
          <ColumnHeader id="MaterialDescription" display="Material Description" />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={4}>
          <ColumnHeader id="MaterialParameters" display="Material Parameters" />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedEquipment} displayName="equipment" />
      {sortedEquipment.map((unit) => (
        <React.Fragment key={`${unit.Serial}`}>
          {unit.Processes.map((process) => (
            <React.Fragment key={`${unit.Serial}-${process.PartNumber}`}>
              <Grid container spacing={2} columns={16}>
                <Grid size={{ xs: 6, sm: 2 }}>{unit.Name}</Grid>
                <Grid size={{ xs: 5, sm: 2 }}>{unit.Serial}</Grid>
                <Grid size={{ xs: 5, sm: 2 }}>{unit.Location}</Grid>
                <Grid size={{ xs: 6, sm: 2 }}>{process.PartNumber}</Grid>
                <Grid size={{ xs: 10, sm: 4 }}>{process.Description}</Grid>
                <Grid size={{ xs: 16, sm: 4 }}>
                  <Grid container justifyContent="space-between">
                    <Grid>
                      <FormattedParameters Parameters={process.Parameters} />
                    </Grid>
                    <Grid>
                      <ControlledProcessForm
                        process={process}
                        equipment={unit}
                        part={part}
                        partList={partList}
                        setTableReload={setTableReload}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider variant="middle" sx={{ mx: -1 }} />
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </Box>
  );
}

export default EquipmentTable;
