import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import ReadableMeasurement from '../../components/common/ReadableMeasurement.jsx';
import ReadablePart from '../../components/common/ReadablePart.jsx';
import FormattedDate from '../../components/FormattedDate.jsx';
import MeasurementsForm from '../../components/MeasurementsForm.jsx';
import useControlLimits from '../../hooks/useControlLimits.jsx';

const sortOptions = {
  defaultSort: {
    field: 'DateOfCollection',
    reverse: false,
  },
};

function MeasurementsTable({
  // Props
  measurements,
  units,
  setTableReload,
  partList,
  processes,
  defaultProcess,
}) {
  const [sortedMeasurements, handleSortSelection, sort] = useSort(measurements || [], sortOptions);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2} columns={16}>
        <Grid size={16}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4" align="center" data-cy="consumables-header">
              Measurements
            </Typography>
            <Box sx={{ textAlign: 'center' }}>
              <MeasurementsForm
                setTableReload={setTableReload}
                partList={partList}
                processes={processes}
                defaultProcess={defaultProcess}
                data-cy="record-measurement-button-row"
              />
            </Box>
          </Stack>
        </Grid>
        <Grid size={{ xs: 4, sm: 2 }}>
          <ColumnHeader
            id="DateOfCollection"
            display="Date"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ColumnHeader
            id="User"
            display="Inspector"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 4, sm: 2 }}>
          <ColumnHeader
            id="LotIdentifier"
            display="Lot"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={6}>
          <ColumnHeader
            id="ProductPartNumber"
            display="Product"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 4, sm: 2 }}>
          <ColumnHeader id="Average" display="Average" />
        </Grid>
        <Grid size={{ xs: 4, sm: 2 }}>
          <ColumnHeader id="StdDev" display="Standard Deviation" />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedMeasurements} displayName="measurements" />
      {sortedMeasurements.map(function MeasurementRow(measurement) {
        const { mean, stdDev } = useControlLimits([measurement]);

        return (
          <React.Fragment key={measurement.Id}>
            <Grid container spacing={2} columns={16} data-cy="measurements-row">
              <Grid size={{ xs: 4, sm: 2 }}>
                <FormattedDate dateString={measurement.DateOfCollection} />
              </Grid>
              <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
                {measurement.User.FullName}
              </Grid>
              <Grid size={{ xs: 4, sm: 2 }}>{measurement.LotIdentifier}</Grid>
              <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={6}>
                <ReadablePart part={measurement.Product} />
              </Grid>
              <Grid size={{ xs: 4, sm: 2 }}>
                <ReadableMeasurement value={mean} units={units} />
              </Grid>
              <Grid size={{ xs: 4, sm: 2 }}>
                <ReadableMeasurement value={stdDev} units={units} />
              </Grid>
            </Grid>
            <Divider variant="middle" sx={{ mx: -1 }} />
          </React.Fragment>
        );
      })}
    </Box>
  );
}

export default MeasurementsTable;
