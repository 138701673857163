import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import DocumentLink from '../../components/common/DocumentLink.jsx';
import FormattedMultilineRow from '../../components/common/FormattedMultilineRow.jsx';
import PartsForm from '../../components/PartsForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import useAuth0Roles from '../../hooks/auth0Roles.jsx';
import EquipmentTable from './EquipmentTable.jsx';

function PartsRow({
  // Props
  part,
  partList,
  setTableReload,
}) {
  //---------------------------------------------------------------------------
  // States
  //---------------------------------------------------------------------------
  const [expanded, setExpanded] = React.useState(false);

  const { editMode } = React.useContext(PageContext);
  const roles = useAuth0Roles();
  const canEdit = React.useMemo(
    () => roles.includes('Progress-Tracker Admin') && !part.IsDisabled,
    [roles, part.IsDisabled]
  );
  //---------------------------------------------------------------------------
  // Callback Functions
  //---------------------------------------------------------------------------
  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);
  return (
    <Card
      square
      sx={{ opacity: part.IsDisabled ? 0.5 : 1.0, ...(expanded && { my: 1, boxShadow: 6 }) }}
      data-cy={part.PartNumber}
      data-testid={part.IsDisabled ? 'disabled-part-row' : 'enabled-part-row'}
    >
      <Grid
        container
        columns={32}
        onClick={handleExpandClick}
        sx={{
          cursor: 'pointer',
          p: '0.5rem',
          minHeight: '3.5rem',
          alignItems: 'center',
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
          ...(expanded && { fontWeight: 'bold' }),
        }}
        data-cy="parts-row"
      >
        <Grid data-cy="part-number-column" size={{ xs: 8, sm: 3 }}>
          {part.PartNumber}
        </Grid>
        <Grid data-cy="description-column" size={{ xs: 14, sm: 6 }}>
          {part.Description}
        </Grid>
        <Grid data-cy="qcp-column" size={{ xs: 8, sm: 5 }}>
          <DocumentLink documentId={part.QualityControlPlanId} data-cy="doc-link" />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="dmr-column" size={4}>
          <DocumentLink documentId={part.DeviceMasterRecord} />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="gtin-column" size={4}>
          {part.GTIN || '-'}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="vendor-column" size={2}>
          {part.Vendor || '-'}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} data-cy="notes-column" size={7}>
          <FormattedMultilineRow cell={part.Notes || '-'} />
        </Grid>
        {canEdit && editMode && (
          <Grid size={{ xs: 2, sm: 1 }}>
            <PartsForm part={part} setTableReload={setTableReload} />
          </Grid>
        )}
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
        <EquipmentTable
          equipment={part.Equipment}
          part={part}
          partList={partList}
          setTableReload={setTableReload}
        />
      </Collapse>
    </Card>
  );
}

export default PartsRow;
