import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

import FormStringInput from '../../components/common/FormStringInput.jsx';

function TestSamplesInput({
  // Props
  control,
  testUnits,
  sampleCount,
}) {
  const title = React.useMemo(() => {
    return sampleCount ? `Test Samples (${testUnits})` : '';
  }, [testUnits, sampleCount]);

  const fields = [...Array(sampleCount).keys()];

  return (
    <Box data-cy="test-samples-table">
      <Typography align="left" gutterBottom variant="h6">
        {title}
      </Typography>
      <Grid container columns={10} spacing={1}>
        {fields.map((index) => (
          <Grid
            key={`samples-row-${index}`}
            data-cy={`samples-row-${index}`}
            size={{ xs: 10, md: 2 }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <FormStringInput
                control={control}
                name={`testSamples.${index}`}
                label={`Sample ${index + 1}`}
                type="number"
                variant="outlined"
                size="small"
                required
                rules={{
                  required: 'Required',
                }}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default TestSamplesInput;
