import React from 'react';

import SyncAltIcon from '@mui/icons-material/SyncAlt';

import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Paper from '@mui/material/Paper';
import useTheme from '@mui/material/styles/useTheme';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import DocumentLink from '../../components/common/DocumentLink.jsx';
import ReadableEquipment from '../../components/common/ReadableEquipment.jsx';
import ReadablePart from '../../components/common/ReadablePart.jsx';
import ControlledProcessForm from '../../components/ControlledProcessForm.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import MeasurementsTable from './MeasurementsTable.jsx';
import PartsTable from './PartsTable.jsx';

function ProcessRow({
  // Props
  process,
  setTableReload,
  partList,
  processes,
}) {
  const { editMode } = React.useContext(PageContext);

  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = React.useCallback(() => {
    setExpanded((previous) => !previous);
  }, [setExpanded]);

  return (
    <Card square sx={{ ...(expanded && { my: 1, boxShadow: 6 }) }} key={process.PartNumber}>
      <Grid
        data-cy={`process-row-${process?.TestType}`}
        container
        onClick={handleExpandClick}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          p: '0.5rem',
          minHeight: '3.5rem',
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          ...(expanded && { fontWeight: 'bold' }),
        }}
        spacing={1}
      >
        <Grid size={{ xs: 4, md: 3 }}>
          <DocumentLink documentId={process.DocumentId} />
        </Grid>
        <Grid size={{ xs: 4, md: 3 }}>
          <ReadablePart part={process.ToolPart} />
        </Grid>
        <Grid size={{ xs: 4, md: 3 }}>
          <ReadableEquipment equipment={process.Equipment} />
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={2}>
          <Typography>
            {process.Measurements?.length > 0
              ? `${process.Measurements?.length}`
              : 'No Measurements'}
          </Typography>
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' }, textAlign: 'right' }} size={1}>
          {editMode && (
            <ControlledProcessForm
              process={process}
              partList={partList}
              setTableReload={setTableReload}
              data-cy={`edit-process-${process.Id}`}
            />
          )}
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" sx={{ my: 2, mx: -1 }} />
        <Container maxWidth="md" data-cy="expanded-process">
          <Grid container spacing={3}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Grid container spacing={2}>
                <Grid size={7}>
                  <TextField
                    inputProps={{
                      'data-cy': 'test-type',
                    }}
                    fullWidth
                    label="Test Type"
                    value={process.TestType}
                    disabled
                  />
                </Grid>
                <Grid size={1} />
                <Grid size={4}>
                  <TextField
                    inputProps={{
                      'data-cy': 'required-samples',
                    }}
                    fullWidth
                    label="Test Samples"
                    value={process.RequiredSampleCount}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container sx={{ my: 2 }}>
                <Grid size={5}>
                  <TextField
                    inputProps={{
                      'data-cy': 'test-min',
                    }}
                    fullWidth
                    label="Test Min"
                    value={`${process.TestMinimum} ${
                      process.TestUnits === 'unitless' ? '' : process.TestUnits
                    }`}
                    disabled
                  />
                </Grid>
                <Grid size={2} sx={{ textAlign: 'center' }}>
                  <SyncAltIcon />
                </Grid>
                <Grid size={5}>
                  <TextField
                    inputProps={{
                      'data-cy': 'test-max',
                    }}
                    fullWidth
                    label="Test Max"
                    value={`${process.TestMaximum} ${
                      process.TestUnits === 'unitless' ? '' : process.TestUnits
                    }`}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <TableContainer
                component={Paper}
                data-cy="process-parameters"
                sx={{
                  outlineColor: theme.palette.divider,
                  outlineStyle: 'solid',
                  outlineWidth: '1px',
                  boxShadow: 'none',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {process.Parameters.map(({ Name, Value, Units }) => {
                      return (
                        <TableRow
                          key={`${Name}-${Value}-${Units}`}
                          sx={{
                            '&:nth-of-type(odd)': {
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                        >
                          <TableCell>{Name}</TableCell>
                          <TableCell>
                            {Value} {Units === 'unitless' ? '' : Units}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {!process.Parameters?.length && (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <Typography align="center" color="text.secondary" sx={{ pt: 1 }}>
                            There are no parameters for this process.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Container>
        <Container sx={{ margin: 'auto' }} maxWidth="md">
          <PartsTable parts={process.Parts} />
        </Container>
        <Container sx={{ margin: 'auto' }} maxWidth="xl">
          <MeasurementsTable
            measurements={process.Measurements}
            units={process.TestUnits}
            partList={partList}
            processes={processes}
            defaultProcess={process}
            setTableReload={setTableReload}
          />
        </Container>
      </Collapse>
    </Card>
  );
}

export default ProcessRow;
