import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

//---------------------------------------------------------------------------
// TZ Components
//---------------------------------------------------------------------------
import { useSort } from '@tzmedical/react-hooks';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import DocumentLink from '../../components/common/DocumentLink.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import DocumentContext from '../../contexts/DocumentContext.jsx';

const sortOptions = {
  defaultSort: {
    field: 'PartNumber',
    reverse: false,
  },
};

function PartsTable({
  // Props
  parts,
}) {
  const { documents } = React.useContext(DocumentContext);
  const partsWithDocuments = React.useMemo(() => {
    return parts.map((part) => {
      if (documents?.length === 0) {
        return part;
      }
      const documentForPart = documents.find((doc) => doc.id === part.QualityControlPlanId);
      return {
        ...part,
        DocumentName: documentForPart?.name || '-',
      };
    });
  }, [documents, parts]);
  const [sortedParts, handleSortSelection, sort] = useSort(partsWithDocuments || [], sortOptions);

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2} sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
        <Grid size={12}>
          <Typography variant="h4" align="center" data-cy="consumables-header">
            Consumables
          </Typography>
        </Grid>
        <Grid size={{ xs: 6, sm: 3 }}>
          <ColumnHeader
            id="PartNumber"
            display="Part Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={5}>
          <ColumnHeader
            id="QualityControlPlanId"
            display="QCP"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 6, sm: 4 }}>
          <ColumnHeader
            id="Description"
            display="Description"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedParts} displayName="parts" />
      {sortedParts.map((part) => (
        <React.Fragment key={part.PartNumber}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 6, sm: 3 }}>{part.PartNumber}</Grid>
            <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={5}>
              <DocumentLink documentId={part.QualityControlPlanId} />
            </Grid>
            <Grid size={4}>{part.Description}</Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default PartsTable;
