import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useSort } from '@tzmedical/react-hooks';

import ColumnHeader from '../../components/common/ColumnHeader.jsx';
import DocumentLink from '../../components/common/DocumentLink.jsx';
import NoneFound from '../../components/common/NoneFound.jsx';
import ReadablePart from '../../components/common/ReadablePart.jsx';
import ControlledProcessForm from '../../components/ControlledProcessForm.jsx';
import DocumentContext from '../../contexts/DocumentContext.jsx';

const sortOptions = {
  defaultSort: {
    field: 'DocumentId',
    reverse: false,
  },
};

function ProcessTable({
  // Props
  processes,
  equipment,
  partList,
  setTableReload,
}) {
  const { documents } = React.useContext(DocumentContext);

  const processesWithDocuments = React.useMemo(() => {
    return processes.map((process) => {
      const [ktDocument] = documents.filter(({ id }) => id === process.DocumentId);
      return {
        DocumentName: ktDocument?.name,
        ...process,
      };
    });
  }, [processes, documents]);

  const [sortedProcesses, handleSortSelection, sort] = useSort(
    processesWithDocuments || [],
    sortOptions
  );

  return (
    <Box sx={{ mt: 3 }}>
      <Grid container spacing={2} sx={{ alignItems: 'flex-end', justifyContent: 'center' }}>
        <Grid size={12}>
          <Stack direction="row" spacing={1} sx={{ justifyContent: 'center' }}>
            <Typography variant="h4" align="center">
              Controlled Processes
            </Typography>
            <ControlledProcessForm
              equipment={equipment}
              partList={partList}
              setTableReload={setTableReload}
            />
          </Stack>
        </Grid>
        <Grid size={4}>
          <ColumnHeader
            id="DocumentName"
            display="Document Name"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={4}>
          <ColumnHeader
            id="ToolPartNumber"
            display="Tool Part"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={4}>
          <ColumnHeader
            id="RecordedMeasurements"
            display="Control Measurements"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
      <Divider variant="middle" sx={{ mx: -1 }} />
      <NoneFound list={sortedProcesses} displayName="processes" />{' '}
      {sortedProcesses.map((process) => (
        <React.Fragment key={process.Id}>
          <Grid container spacing={2} sx={{ alignItems: 'center' }}>
            <Grid size={4}>
              <DocumentLink documentId={process.DocumentId} />
            </Grid>
            <Grid size={4}>
              <ReadablePart part={process.ToolPart} />
            </Grid>
            <Grid size={3}>
              {process.Measurements?.length > 0
                ? `${process.Measurements?.length}`
                : 'No Measurements'}
            </Grid>
            <Grid size={1}>
              <ControlledProcessForm
                process={process}
                equipment={equipment}
                partList={partList}
                setTableReload={setTableReload}
                data-cy={`edit-process-${process.Id}`}
              />
            </Grid>
          </Grid>
          <Divider variant="middle" sx={{ mx: -1 }} />
        </React.Fragment>
      ))}
    </Box>
  );
}

export default ProcessTable;
