import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function PartsHeader({
  // Props
  sort,
  setSort,
}) {
  //---------------------------------------------------------------------------
  // Update the `sort` object for use by sortManager
  //---------------------------------------------------------------------------
  const handleSortSelection = (field) => setSort(field);

  return (
    <Box sx={{ p: 1 }}>
      <Grid
        container
        columns={32}
        id="headersRow"
        sx={{ alignItems: 'flex-end' }}
        data-cy="parts-header"
      >
        <Grid size={{ xs: 8, sm: 3 }}>
          <ColumnHeader
            id="PartNumber"
            display="Part Number"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 14, sm: 6 }}>
          <ColumnHeader
            id="Description"
            display="Description"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 8, sm: 5 }}>
          <ColumnHeader
            id="QualityControlPlanId"
            display="QCP"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={4}>
          <ColumnHeader
            id="DeviceMasterRecord"
            display="DMR"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={4}>
          <ColumnHeader
            id="GTIN"
            display="GTIN"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={2}>
          <ColumnHeader
            id="Vendor"
            display="Vendor"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={8}>
          <ColumnHeader
            id="Notes"
            display="Notes"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default PartsHeader;
