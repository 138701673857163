import React from 'react';

import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import WarningIcon from '@mui/icons-material/Warning';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import EquipmentForm from '../../components/EquipmentForm.jsx';
import FormattedDate from '../../components/FormattedDate.jsx';
import PageContext from '../../contexts/PageContext.jsx';
import MaintenanceActivitiesTable from './MaintenanceActivitiesTable.jsx';
import MaintenanceRequirementsTable from './MaintenanceRequirementsTable.jsx';
import ProcessTable from './ProcessTable.jsx';

function EquipmentRow({
  // Props
  equipment,
  equipmentList,
  partList,
  setTableReload,
}) {
  const { editMode } = React.useContext(PageContext);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = React.useCallback(() => setExpanded((previous) => !previous), []);
  const exceedsAnyThresholds = React.useMemo(
    () => equipment.MaintenanceRequirements.some((requirement) => requirement.ThresholdExceeded),
    [equipment.MaintenanceRequirements]
  );

  return (
    <Card
      square
      sx={{
        backgroundColor: (theme) =>
          equipment.IsDisabled ? alpha(theme.palette.divider, 0.1) : theme.palette.background,
        ...(expanded && { my: 1, boxShadow: 6 }),
      }}
      data-cy={`equipment-row-${equipment.Serial}`}
      data-testid={equipment.IsDisabled ? 'disabled-equipment-row' : 'enabled-equipment-row'}
    >
      <Grid
        container
        columns={20}
        onClick={handleExpandClick}
        sx={{
          cursor: 'pointer',
          padding: '0.5rem',
          minHeight: '3.5rem',
          alignItems: 'center',
          justifyContent: 'flex-start',
          transition: 'background-color 0.2s',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
          ...(expanded && { fontWeight: 'bold' }),
        }}
      >
        <Grid size={{ xs: 10, sm: 4 }}>
          <Stack spacing={1} direction="row" sx={{ alignItems: 'center' }}>
            {exceedsAnyThresholds && !equipment.IsDisabled && (
              <Tooltip
                title="One or more procedures require maintenance"
                arrow
                placement="bottom-start"
              >
                <WarningIcon color="warning" data-cy={`threshold-exceeded-${equipment.Serial}`} />
              </Tooltip>
            )}
            {equipment.IsDisabled && (
              <Tooltip title="Equipment is disabled" arrow placement="bottom-start">
                <DoDisturbIcon color="error" data-cy={`disabled-${equipment.Serial}`} />
              </Tooltip>
            )}
            <Typography variant="body1">{equipment.Name}</Typography>
          </Stack>
        </Grid>
        <Grid size={{ xs: editMode ? 9 : 10, sm: 2 }}>{equipment.Serial}</Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ sm: 4, md: 2 }}>
          {equipment.Manufacturer || '-'}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ sm: 3, md: 2 }}>
          {equipment.Location}
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block' } }} size={{ sm: 4, md: 2 }}>
          <FormattedDate dateString={equipment.LastActivityDate} />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'block ' } }} size={{ sm: editMode ? 3 : 4, md: 2 }}>
          <FormattedDate dateString={equipment.NextActivityDate} />
        </Grid>
        <Grid sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} size={2}>
          {equipment.Processes.length || '-'}
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={editMode ? 1 : 2}>
          {equipment.CurrentOperatingHours || '-'}
        </Grid>
        <Grid sx={{ display: editMode ? 'block' : 'none', textAlign: 'right' }} size={1}>
          <EquipmentForm
            equipment={equipment}
            equipmentList={equipmentList}
            setTableReload={setTableReload}
          />
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ p: '1em' }}>
          <Divider variant="middle" sx={{ my: 1, mx: -1 }} />
          <MaintenanceRequirementsTable
            maintenanceRequirements={equipment.MaintenanceRequirements}
            equipment={equipment}
            setTableReload={setTableReload}
          />
          <MaintenanceActivitiesTable
            maintenanceActivities={equipment.Activities}
            equipment={equipment}
            setTableReload={setTableReload}
          />
          <ProcessTable
            processes={equipment.Processes}
            equipment={equipment}
            partList={partList}
            setTableReload={setTableReload}
          />
        </Box>
      </Collapse>
    </Card>
  );
}

export default EquipmentRow;
