import React from 'react';

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';

//---------------------------------------------------------------------------
// Application Components
//---------------------------------------------------------------------------
import ColumnHeader from '../../components/common/ColumnHeader.jsx';

function ProcessHeader({
  // Props
  sort,
  setSort,
}) {
  const handleSortSelection = React.useCallback((field) => setSort(field), [setSort]);

  return (
    <Box sx={{ p: 1 }} data-cy="process-header">
      <Grid container id="headersRow" sx={{ alignItems: 'flex-end' }} spacing={1} columns={12}>
        <Grid size={{ xs: 4, md: 3 }}>
          <ColumnHeader
            id="DocumentId"
            display="Document"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 4, md: 3 }}>
          <ColumnHeader
            id="ToolPartNumber"
            display="Tool Part"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid size={{ xs: 4, md: 3 }}>
          <ColumnHeader
            id="EquipmentSerial"
            display="Equipment"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
        <Grid sx={{ display: { xs: 'none', md: 'block' } }} size={2}>
          <ColumnHeader
            id="RecordedMeasurements"
            display="Control Measurements"
            sortField={sort.field}
            reverseSort={sort.reverse}
            onClick={handleSortSelection}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProcessHeader;
